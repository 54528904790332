var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.blurList),expression:"blurList"}],staticClass:"date-select",class:{
    'date-select--focus': _vm.focused || _vm.opened,
    'date-select--filled': _vm.singleSelected,
    'date-select--input': _vm.search.length || _vm.focused,
    'date-select--drop': _vm.opened,
    'date-select--drop-top': _vm.listTop,
  }},[_c('div',{staticClass:"date-select__container"},[(_vm.title)?_c('label',{staticClass:"date-select__title",attrs:{"for":_vm.singleSelected}},[_vm._v("\n      "+_vm._s(_vm.title)+"\n      "),_c('span',[_vm._v(_vm._s(_vm.required ? "*" : ""))])]):_vm._e(),_c('span',{staticClass:"date-select__field-container"},[_c('input',{ref:"input",staticClass:"date-select__field",attrs:{"id":_vm.singleSelected,"placeholder":_vm.title,"readonly":""},domProps:{"value":_vm.formatDate(_vm.singleSelected) || _vm.emptyPlaceholder},on:{"blur":_vm.blur,"focus":_vm.focus}}),(_vm.singleSelected)?_c('button',{staticClass:"date-select__clear",attrs:{"type":"button"},on:{"click":_vm.clean}},[_c('IconComponent',{attrs:{"name":"close"}})],1):_vm._e(),_c('button',{staticClass:"date-select__action",attrs:{"tabindex":"-1","type":"button"},on:{"click":_vm.toggle}},[_c('IconComponent',{attrs:{"name":"calendar"}})],1)])]),_c('ClientOnly',[(_vm.opened)?_c('div',{staticClass:"date-select__drop"},[_c('DatePicker',{attrs:{"first-day-of-week":2,"is-range":_vm.range,"mode":_vm.mode,"locale":"ru","title-position":"left","trim-weeks":""},on:{"input":_vm.handleInput},model:{value:(_vm.singleSelected),callback:function ($$v) {_vm.singleSelected=$$v},expression:"singleSelected"}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }