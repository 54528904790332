<template>
  <main class="documents-page page">
    <BreadcrumbsComponent title="Документы" />
    <div class="container-padding">
      <div class="documents-page__inner page__inner">
        <div class="documents-page__filters filters">
          <SelectComponent
            v-model="form.type.value"
            :errors="form.type.errors"
            :options="document_types"
            multiple
            label-name="title"
            title="Тип документа"
            placeholder="Все"
          />
          <InputComponent
            v-model.trim="form.title.value"
            :errors="form.title.errors"
            title="Поиск по заголовку"
          >
            <template v-slot:action>
              <IconComponent name="search-normal" />
            </template>
          </InputComponent>
          <DateComponent
            v-model="form.accepted_at.value"
            emptyPlaceholder="Любая"
            mode="date"
            range
            title="Дата принятия"
          />
          <DateComponent
            v-model="form.published_at.value"
            emptyPlaceholder="Любая"
            mode="date"
            range
            title="Дата размещения"
          />
          <div class="documents-page__actions">
            <button class="btn btn--lg btn--outline" @click="updatePage">Поиск</button>
            <button class="btn btn--lg btn--main" @click="resetPage">Сбросить</button>
          </div>
        </div>
        <div v-if="documents && documents.length" class="documents-page__content">
          <LoadingIndicator v-if="loading" title="Загрузка" />
          <DocumentListComponent v-if="!loading" :documents="documents" />
          <PaginationComponent
            v-if="Math.ceil(total / first) > 1 && !loading"
            :page="form.page.value"
            :total="Math.ceil(total / first)"
            @change="updatePagination"
          />
        </div>
        <p v-else>В данный момент информация отсутствует</p>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import DocumentListComponent from "./components/DocumentListComponent.vue";
import PaginationComponent from "components/PaginationComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import DateComponent from "components/inputs/DateComponent.vue";
import SelectComponent from "components/inputs/select/index.vue";
import IconComponent from "components/IconComponent.vue";
import DOCUMENTS_PAGE from "gql/pages/DocumentsPage.graphql";
import moment from "moment";
import LoadingIndicator from "components/LoadingIndicator.vue";
import DEFAULT from "gql/queries/default.graphql";

const resolver = (queries) => ({
  title: {
    value: queries?.title,
    error: [],
  },
  accepted_at: {
    value: queries.accepted_at ? JSON.parse(queries.accepted_at) : undefined,
    error: [],
  },
  published_at: {
    value: queries.published_at ? JSON.parse(queries.published_at) : undefined,
    error: [],
  },
  type: {
    value: queries.type ? JSON.parse(queries.type) : undefined,
    error: [],
  },
  page: {
    value: queries.page ? parseInt(queries?.page) : 1,
  },
});
export default {
  name: "DocumentsPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: DEFAULT,
      })
      .then(({ data }) => {
        store.dispatch("save", data);
      })
      .catch(() => {});
  },
  created() {
    /**
     * Парсим параметры с роутера и сохраняем в this.form в нужном нам формате
     **/
    this.form = { ...resolver(this.$route.query) };
    this.get();
  },
  data() {
    return {
      first: 12,
      loading: false,
      form: {
        title: {
          value: null,
          errors: [],
        },
        accepted_at: {
          value: null,
          errors: [],
        },
        published_at: {
          value: null,
          errors: [],
        },
        type: {
          value: [],
          errors: [],
        },
        page: {
          value: 1,
          error: [],
        },
      },
    };
  },
  methods: {
    updatePagination($event) {
      this.form.page.value = parseInt($event);
      this.updatePage();
    },
    updatePage() {
      /**
       * Проверяем если параметры в this.form поменялись то обновляем роутер и делаем запрос на получение данных
       * если нет то выходим из функции
       **/
      if (JSON.stringify(this.form) === JSON.stringify(resolver(this.$route.query))) return;
      this.$router.push({ name: "documents", query: { ...this.toQuery() } });
      this.get();
    },
    toQuery() {
      return {
        title: this.form.title.value,
        accepted_at: JSON.stringify(this.form.accepted_at.value),
        published_at: JSON.stringify(this.form.published_at.value),
        type: JSON.stringify(this.form.type.value),
        page: parseInt(this.form.page.value),
      };
    },
    resetPage() {
      this.form.title.value = undefined;
      this.form.accepted_at.value = undefined;
      this.form.published_at.value = undefined;
      this.form.type.value = [];
      this.form.page.value = 1;
      /** Не суди да не судим будешь **/
      this.$router.push({ name: "documents" }).catch(() => {});
      this.get();
    },
    get() {
      if (!this.loading) {
        this.loading = true;
        let variables = {
          first: this.first,
          page: this.form.page.value,
          title: this.form.title.value,
          type: this.form.type.value?.map((item) => item.id),
          accepted_at_start_date: this.form.accepted_at.value
            ? moment(this.form.accepted_at.value.start).format("YYYY-MM-DD HH:mm:ss")
            : undefined,
          accepted_at_end_date: this.form.accepted_at.value
            ? moment(this.form.accepted_at.value.end).format("YYYY-MM-DD HH:mm:ss")
            : undefined,
          published_at_start_date: this.form.published_at.value
            ? moment(this.form.published_at.value.start).format("YYYY-MM-DD HH:mm:ss")
            : undefined,
          published_at_end_date: this.form.published_at.value
            ? moment(this.form.published_at.value.end).format("YYYY-MM-DD HH:mm:ss")
            : undefined,
        };
        this.$apollo.provider.defaultClient
          .query({
            query: DOCUMENTS_PAGE,
            variables,
          })
          .then(({ data }) => {
            this.$store.dispatch("documents/save", data);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
  computed: {
    documents() {
      return this.$store.state.documents.acts_paginate?.data;
    },
    total() {
      return this.$store.state.documents.acts_paginate.paginatorInfo?.total;
    },
    document_types() {
      return this.$store.state.document_types;
    },
    // links() {
    //   return [
    //     {
    //       title: "Документы",
    //       to: { name: "documents" },
    //     },
    //   ];
    // },
  },
  components: {
    LoadingIndicator,
    InputComponent,
    SelectComponent,
    DateComponent,
    IconComponent,
    PaginationComponent,
    DocumentListComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
.documents-page {

  &__filters {
    grid-template-columns 240fr 300fr 250fr 250fr 220fr
    grid-gap 35px
    +below(1200px) {
      grid-template-columns 1fr 1fr
      grid-gap 20px
    }
    +below(625px) {
      grid-template-columns 1fr
    }
  }

  &__actions {
    display flex
    gap 15px
    align-items center
  }

  &__content {
    display: flex;
    flex-direction: column;
    grid-gap: var(--gap);
  }
}
</style>
