<template>
  <div v-if="documents && documents.length" class="document-list">
    <DocumentCardComponent
      v-for="(document, i) in documents"
      :key="i"
      :document="document"
      :activity="activity"
    />
  </div>
</template>

<script>
import DocumentCardComponent from "./DocumentCardComponent.vue";

export default {
  name: "DocumentListComponent",
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    activity: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DocumentCardComponent,
  },
};
</script>

<style lang="stylus">
.document-list {
  display grid
  grid-template-columns repeat(2, 1fr)
  grid-gap 40px
  +below(900px) {
    grid-template-columns 1fr
    gap 20px
  }
}
</style>
