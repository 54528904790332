var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"check",class:{
    'check--focus': _vm.focus,
    'check--disabled': _vm.disabled,
    'check--error': _vm.error,
    'check--checked': _vm.checked && !_vm.indeterminate,
    'check--indeterm': _vm.indeterminate,
  }},[_c('input',{attrs:{"disabled":_vm.disabled,"type":"checkbox"},domProps:{"checked":_vm.checked},on:{"focus":function($event){_vm.focus = true},"blur":function($event){_vm.focus = false},"change":function($event){return _vm.$emit('change', $event.target.checked)}}}),_c('span',{staticClass:"check__state"},[(_vm.state)?_c('IconComponent',{attrs:{"name":_vm.state}}):_vm._e()],1),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }